import React from "react"
import Img from "gatsby-image"
import { navigate } from "gatsby-link"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Bio from "../components/bio"

import "../utils/normalize.css"
import "../utils/css/screen.css"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout title="Alex Covo Studio NYC">
        <SEO
          title="M/WBE Certified Advertising - Photo - Video: Alex Covo Studio NYC"
          keywords={[
            `photographer`,
            "m/wbe",
            "minority-owned business enterprise",
            `fashion`,
            `lifestyle`,
            `fashion photographer`,
            `lifestyle photographer`,
            `commercial photographer`,
            `nyc`,
            `art`,
            `videographer`,
            `advertising`,
            `agency`,
            `web-development`,
            `gatsby`,
            `javascript`,
            `react`,
          ]}
        />

        <article className="post-content page-template no-image">
          <div className="post-content-body">
            <section>
              <div className="row1">
                <div className="column2">
                  <section className="section">
                    <div className="container">
                      <div className="content">
                        {/*                      <form
                        name="alexcovo-contact-form-2020" 
                        method="POST" 
                        data-netlify="true"
                        data-netlify-honeypot="bot-field">
  */}
                        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                        {/*                        <input type="hidden" name="form-name" value="alexcovo-contact" />
                          <div hidden>
                            <label>
                              Don’t fill this out:{' '}
                              <input name="bot-field" onChange={this.handleChange} />
                            </label>
                          </div>
                          <div className="col-lg-12 text-center">
                            <h2 className="contactTitle">Write us</h2>
                            <h3 className="subTitle">We would love to hear from you!</h3>
                            <p>For more information contact us at <a href="mailto:alex@alexcovo.com" target="_top"><b>alex@alexcovo.com</b></a> or send a request via the form below.
                            </p>
                            <br></br>
                          </div>
                        <p>
                          <label>Your Name: <input type="text" name="name" required/></label>   
                        </p>
                        <p>
                          <label>Your Email: <input type="email" name="email" required/></label>
                        </p>
                        <p>
                          <label>Message: <textarea name="message"  rows="6" required></textarea></label>
                        </p>
                        <p>
                          <button type="submit">Send</button>
                        </p>
                      </form>
*/}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <Bio />
          </div>
        </article>
      </Layout>
    )
  }
}
