/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <section>
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author}
              imgStyle={{
                borderRadius: `50%`,
              }}
            />
            <p>
              All Photos, videos and goodies on this website were created by{" "}
              <strong>{author}</strong> who lives and works in New York City.
              {` `}
              This web3 site is powered by
              <a href={`https://ethereum.org/en/`}> Ethereum,</a>
              <a href={`https://filecoin.io/`}> Filecoin,</a>
              <a href={`https://ipfs.io/`}> IPFS,</a>
              <a href={`https://www.gatsbyjs.com/`}> Gatsbyjs,</a>
              <a href={`https://ens.domains/`}> Ethereum Name Service,</a>
              <a href={`https://fleek.co/`}> Fleek,</a>
              <a href={`https://slate.host/`}> Slate </a>and
              <a href={`https://metamask.io/`}> Metamask</a>.
              <p />
              You can access this site from a web3 browser at alexcovo.eth or if
              you are using a web2.0 browsers use alexcovo.eth.link and
              alexcovo.com instead.
              {` `}
            </p>
            <p>
              The best way to reach us directly is to send a message to{" "}
              <a href="mailto:alex@alexcovo.com">alex@alexcovo.com</a> or you
              can follow us on{" "}
              <a href={`https://instagram.com/${social.instagram}`}>
                instagram.
              </a>
            </p>
            <hr></hr>
          </section>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          instagram
        }
      }
    }
  }
`

export default Bio
